<template>
  <v-row justify="center">
    <v-dialog v-model="shower" scrollable  max-width="500px" persistent>
      <v-card>
          <v-card-title>Create Relation Inbox<v-spacer></v-spacer>
            <v-icon :color="color.theme" dense dark size="35">device_hub</v-icon>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
              <v-layout row wrap align-center justify-center>
                  <v-flex lg11 xs11 ma-5>
                    <v-card>
                        <v-container fluid>
                            <v-row align="center">
                            <v-col cols="12">
                                <v-layout row wrap justify-center>
                                    <v-flex lg10 xs11 class="text-center">
                                        <v-text-field
                                        label="NameRelation"
                                        v-model="namerelation"
                                        persistent-hint
                                        outlined
                                        dense
                                        >
                                        </v-text-field>
                                    </v-flex>
                                </v-layout>
                                <v-layout row wrap justify-center>
                                    <v-flex lg10 xs11 class="text-center">
                                        <v-text-field
                                        label="Filename"
                                        :value="Filenamerelation"
                                        persistent-hint
                                        outlined
                                        dense
                                        readonly
                                        >
                                        </v-text-field>
                                    </v-flex>
                                </v-layout>
                                <div v-if="loaddataprogress === true" class="text-center">
                                  <v-progress-circular
                                      indeterminate
                                      size="64"
                                      :color="color.theme"
                                    >
                                    <span :style="colorProgress">&nbsp; &nbsp; loading</span>
                                    </v-progress-circular>
                                </div>
                                <v-list>
                                  <v-subheader>Choose Relation</v-subheader>
                                      <v-divider></v-divider>
                                  <v-list-item v-for="(item,i) in statusrelation" :key="i">
                                    <v-list-item-content class="pl-2">
                                      <v-checkbox v-model="selected" :label="item" :value="item"></v-checkbox>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                            </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                  </v-flex>
              </v-layout>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
              <v-spacer></v-spacer>
             <v-btn
              color="red"
              outlined
              @click="$emit('closedialog'),namerelation='',selected=[]"
            >{{$t('uploadfolder.buttonclose') }}</v-btn>
            <v-btn
            :color="color.theme"
            dark
            @click="apiAdd()"
            >{{$t('changeName.submit')}}</v-btn>

          </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
//const editsharefile = () => import("../optional/dialog-editsharefile");
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});
  export default {
    props: ["show","fileinbox"],
    data () {
      return {
          namerelation:'',
          Filenamerelation:'',
          newdata:[],
          id:'',
          name:'',
          file:[],
          selected:[],
          statusrelation:[],
          newdatamodel:[],
          loaddataprogress:true,
          newdata:'',
          newdatamodel: []
      }
    },
    computed:{
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId"
    ]),
       shower: {
      get() {
        if (this.show === true) {
        this.checkstatus()
        this.checkloaddata()
        }
        return this.show;
      }
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    }
    },
    methods:{
       async apiAdd(){
            this.newdata = ''
            this.newdatamodel = []
            let payload;
            if (this.namerelation === "") {
                Toast.fire({
                icon: "error",
                title: "Error Please enter name"
              });
            }else{
            console.log("selected",this.selected);
              for(let i = 0; i < this.file.length;i++ ){
                for(let j =0; j<this.selected.length;j++){
                    if(this.file[i].filename === this.selected[j]){
                       this.newdatamodel.push(this.file[i].inbox_id)
                      }
                  }
                }
              if(this.Filenamerelation === this.fileinbox.filename){
                    this.newdata = this.fileinbox.inbox_id
                }
            payload={
                inbox_root:this.newdata,
                inbox_id_child:this.newdatamodel,
                account_id:this.dataAccountId,
                relation_name:this.namerelation
            }
            console.log(payload);
            let auth = await gbfGenerate.generateToken();
           await this.axios
                .post(process.env.VUE_APP_SERVICE_UPDATE_INBOX + "/api/insert_relation_inbox_with_child",payload, { headers: { Authorization: auth.code },},)
                .then(response =>{
                    if (response.data.status === "OK") {
                      Toast.fire({
                        icon: "success",
                            title: "Success"
                         });
                        this.id = response.data.relation_id
                        this.name = response.data.relation_name
                        console.log({relation_id:this.id,relation_name:this.name});
                        this.$emit('checkparameter',{relation_id:this.id,relation_name:this.name})
                        this.namerelation= ''
                        this.selected=[]
                    }else{
                        Toast.fire({
                            icon: "error",
                            title: "Error"
                         });
                    }
                    
                })
            }
        },
        checkstatus(){
           this.Filenamerelation = this.fileinbox.filename
        },
       async checkloaddata(){
          this.statusrelation = []
          let payload
              payload = {
                  limit:"",
                  offset: "",
                  receiver_id:[this.dataAccountActive.business_info["business_id"]],
                  receiver_type:"2",
                  filename:"",
                  fileextension:"",
                  doc_no_start:"",
                  doc_no_end: "",
                  doc_date_start:"",
                  doc_date_end:"",
                  doc_type: "",
                  cre_dtm_start:"",
                  cre_dtm_end:"",
                  sender_name:"",
                  system:this.$route.query.systemid,
                  sellerTaxid:"",
                  sellerBranchid: "",
                  receiver_name: "",
                  receiverTaxid:"",
                  receiverBranchid:"",
                  status:""
          }
          let auth = await gbfGenerate.generateToken();
          this.axios
          .post(
          process.env.VUE_APP_SERVICE_GET_INBOX + "/api/get_file_inbox_child",
          payload,{headers: { Authorization: auth.code },}
          )   
           .then(response=>{
          if (response.data.status === "OK") {

            this.file = response.data.result.data
            for(let i = 0; i <this.file.length; i++){
                if(this.file[i].relation_status === 2 && this.file[i].status === "active"){
                  this.statusrelation.push(this.file[i].filename)
                  console.log("statusrelation",this.statusrelation);
                }
            }
          this.loaddataprogress=false
          }
          else{

          }
          })          
        }
    }
  }
</script>